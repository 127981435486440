<template>
  <div class="width_1434 bg_white">
    <div class="list_text_top">
      <!-- <div
        v-for="(i, index) in Eventnews"
        :key="index"
        @click="gonewlist(i.id)"
      >
        <p v-if="index <= 5">{{ i.contestNewsName }}</p>
      </div> -->
      <div class="list_text_top_item mr30" v-for="item in newsTop" :key="item.id"  @click="gonewlist(item.id)">
        <img :src="constant.URL + item.coverImage" alt="">
        <p>{{item.contestNewsName}}</p>
      </div>
    </div>
    <div class="list_text_cont">
      <div class="list_text_cont_item" v-for="item in newsList" :key="item.id" @click="gonewlist(item.id)">
        <img :src="constant.URL + item.coverImage" alt="">
        <div class="list_text_cont_item_info">
          <p class="list_text_cont_item_info_tit">{{item.contestNewsName}}</p>
          <!-- <p class="list_text_cont_item_info_desc"> {{item.contestNewsName}}</p> -->
          <p class="list_text_cont_item_info_date">{{item.updateTime}}</p>
        </div>
      </div>
     </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      Eventnews: [],
      huabianlist: [],
      newsTop:[],
      newsList:[],
    };
  },
  mounted() {
    console.log(this.$route.query);
    this.newslistbtn(this.$route.query.id);
  },

  methods: {
    newslistbtn(type) {
      //轮播新闻
      const that = this;
      var data = {
        contestAreaId: that.$store.state.spotrsindex,
        contestNewType: type,
      };
      that.api.sports.newslist(data).then((res) => {
        console.log(res);
        if (res.code == 20000) {
          that.Eventnews = res.data;

          this.newsTop = that.Eventnews.slice(0,3);
          this.newsList = that.Eventnews.slice(3);
          console.log(this.newsTop,this.newsList,'this.newsList')
        }
      });
    },
    gonewlist(id) {
      this.$router.push({
        path: "/sportDetail",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.bg_white {
  background: white;
}
.felx_box {
  display: flex;
  flex-wrap: wrap;
}
.width_1434 {
  width: 1354px;
  margin: 0 auto;
  padding:30px 40px;
}
.mr30{
  margin-right:30px;
}
.list_text_top{
  @include flex(row, flex-start,center);
  .list_text_top_item{
    width: 430px;
    height: 272px;
    border-radius: 2px;
    >img{
      width: 430px;
      height: 272px;
    }
    >p{
      margin-top:-80px;
      padding:13px 20px 0 20px;
      font-size: 19px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 30px;
      height: 67px;
      background: rgba(0,0,0,0.7);
      border-radius: 2px;
      position: relative;
      z-index:1;
    }
  }
}
.list_text_cont{
  margin-top:30px;
  .list_text_cont_item{
    padding: 30px 0;
    border-bottom:1px solid #DDDDDD;
    @include flex(row,flex-start,center);

    >img{
      width: 260px;
      height: 132px;
      border-radius: 2px;
      margin-right:20px;
    }
    .list_text_cont_item_info{
      .list_text_cont_item_info_tit{
        font-size: 28px;
        font-weight: bold;
        color: #333333;
        line-height: 40px;
      }
      // .list_text_cont_item_info_desc{
      //   margin:10px 0;
      //   font-size: 16px;
      //   color: #333333;
      //   line-height: 24px;
      // }
      .list_text_cont_item_info_date{
        margin-top:20px;
        text-align: right;
        font-size: 20px;
        font-weight: 400;
        color: #888888;
      }
    }
  }
}
</style>